/*=================================================
Default theme
---------------------------------------------------
Fonts: Raleway & open Sans
---------------------------------------------------
Colors
    AO Blue:            #00ACED
    GRey:               #212226
    White Smoke         #f4f4f4
    White               #fff
    Black               #000

=================================================*/

/*=================================================
||||||| html , Body
=================================================*/

html,
body {
    height: 100%;
}

body {
    color: #212226;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Raleway", sans-serif;
}

p {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
}

section {
    background: #fff;
    overflow: hidden;
}


/*=================================================
||||||| Preloader
=================================================*/

#preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 9999;
    height: 100vh;
    width: 100vw;
    /*This element will stay at the top of all the page elenents */
}



#status {
    background-image: url('../img/preloader/aopreloader.gif');
    background-repeat: no-repeat;
    width: 64px;
    height: 64px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -32px;
    margin-left: -32px;

}

/*=================================================
||||||| Header Logo
=================================================*/

#wirelogo {
    color: #fff;
    width: 50;
    height: auto;
    display: block;
}


/*=================================================
||||||| HOME
=================================================*/

#home {
    background: none;
    height: 100vh;
}

#home-bg-video {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    background: url("../video/ojeda.jpg") no-repeat;
    background-size: cover;
}

#home-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

#home-content {
    width: 100%;
    height: 100%;
    display: table;
}

#home-content-inner {
    display: table-cell;
    vertical-align: middle;
}

#home-heading h1 {
    color: #fff;
    font-size: 55px;
    font-weight: 100;
    text-transform: uppercase;
    margin: 0;
    display: inline-block;
}

#home-heading h1 span {
    color: #00ACED;
    font-weight: 500;
}

#home-text p {
    color: #fff;
    font-size: 17px;
    font-weight: 100;
    margin: 8px 0px 30px 0;

}

/*=================================================
||||||| Buttons
=================================================*/

.btn-general {
    font-family: "Raleway", sans-serif;
    border-radius: 28px;
    font-size: 13px;
    text-transform: uppercase;
    margin: 0 6px;
    padding: 12px 46px 12px 46px;
    -webkit-transition: all .5s;
    transition: all .5s;

}

.btn-home {
    color: #fff;
    border: 1px solid #fff;
}

.btn-home:hover,
.btn-home:focus {
    color: #fff;
    background-color: #00ACED;
    border: 1px solid #00ACED;
}

/* yellow button */

.btn-yellow {
    color: #fff;
    border: 1px solid #fff;
    background-color: #00ACED;
    border: 1px solid #00ACED;
}

.btn-yellow:hover,
.btn-yellow:focus {
    color: #fff;
    background-color: #212226;
    border: 1px solid #212226;
}

.btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 3px 15px;
    border-radius: 4px;
    font-size: 22px;
    display: none;
}

/*=================================================
||||||| arrow down
=================================================*/

#arrow-down {
    position: absolute;
    left: 50%;
    bottom: 20px;
    color: #fff;
    font-size: 32px;
    width: 32px;
    height: 32px;
    text-align: center;
    margin-left: -16px;
    z-index: 1;
}

#arrow-down:hover,
#arrow-down:focus {
    color: #00ACED;

}

/*=================================================
||||||| Content Boxes
=================================================*/

.content-box-lg {
    padding: 120px 0;
}

.content-box-md {
    padding: 100px 0;

}

.content-box-sm {
    padding: 90px 0;

}

/*=================================================
||||||| Vertical & Horizontal Heading
=================================================*/

.vertical-heading h5 {
    color: #00ACED;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    word-spacing: 8px;
    display: inline-block;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    position: relative;
    top: 85px;
    left: -53px;
}

.vertical-heading h2 {
    margin: 0 0 0 35px;
    font-size: 42px;
    font-weight: 100;
    line-height: 45px
}

.horizontal-heading {
    margin-bottom: 80px;
}

.horizontal-heading h5 {
    font-size: 16px;
    color: #00ACED;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 10px;
}

.horizontal-heading h2 {
    font-size: 42px;
    font-weight: 100;
    line-height: 45px;
    margin: 0;
}

/*=================================================
||||||| About Section 01
=================================================*/

/*#about-right p:first-child*/
#about-right p {
    margin-bottom: 25px;
    text-align: justify;
    
}

#about-bottom {
    margin-top: 25px;
}

#about-bottom img {
    margin: 0 auto;
}


/*=================================================
||||||| About Section 02
=================================================*/

#about-02 {
    background-color: #f4f4f4;

}

.about-item {
    background-color: #fff;
    padding: 60px 30px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.about-item i {
    font-size: 42px;
    margin: 0;
}

.about-item h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.about-item hr {
    width: 45px;
    height: 3px;
    background-color: #00ACED;
    margin: 0 auto;
    border: none;
}

.about-item p {
    margin-top: 20px;
    text-align: justify;
}

/* Hover state */

.about-item:hover {
    background-color: #00ACED;
}

.about-item:hover i,
.about-item:hover h3,
.about-item:hover p {
    color: #fff;
}

.about-item:hover hr {
    background-color: #fff;
}

.about-item:hover i {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
}


/* Smooth transition  */

.about-item,
.about-item i,
.about-item hr {
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}

.about-item h3,
.about-item p {
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

/*=================================================
||||||| Team
=================================================*/

#team-left .vertical-heading {
    min-height: 190px;
}

.team-member {
    margin: 8px;
    position: relative;
}

.team-member-overlay {
    background-color: rgba(33, 34, 38, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

.team-member-info {
    width: 100%;
    height: 100%;
    padding: 80px 0;
}

.team-member-info h6 {
    color: #00ACED;
    font-size: 16px;
    margin: 0;
}

.team-member-info p {
    color: #fff;
}

.team-member:hover .team-member-overlay {
    opacity: 0.9;
}

/*=================================================
||||||| Social icons
=================================================*/

ul.social-list {
    padding: 0;
    margin-top: 20px;
}

ul.social-list li {
    display: inline-block;
    padding: 0;
}

ul.social-list li a {
    border: 1px solid #fff;
    width: 35px;
    height: 35px;
    display: inline-block;
    line-height: 35px;
    color: #fff;
    border-radius: 50%;
    -webkit-transition: all 300ms linear;
    transition: all 400ms linear;
}

ul.social-list li:nth-child(1) a:hover {
    background: #3b5998;
    border-color: transparent;
}

ul.social-list li:nth-child(2) a:hover {
    background: #00aced;
    border-color: transparent;
}

ul.social-list li:nth-child(3) a:hover {
    background: #dd4b39;
    border-color: transparent;
}

/*=================================================
||||||| Slider Buttons - recieved help from Prashant (udemi)
=================================================*/

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    color: #212226;
    font-size: 40px;
    background: none;
    margin-right: 10px;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    color: #212226;
    font-size: 40px;
    background: none;
    margin-left: 10px;
}

.owl-carousel .owl-nav button.owl-prev:hover {
    color: #212226;
    background: none;
}

.owl-carousel .owl-nav button.owl-next:hover {
    color: #212226;
    background: none;
}

/*=================================================
||||||| Skills
=================================================*/

#progress-elements {
    padding: 25px 0 0 0;
}

#team-left p {
    text-align: justify;
}

.skill {
    margin-bottom: 20px;

}

.skill h4 {
    line-height: 25px;
    margin: 0;
    font-weight: normal;
}

.progress {
    height: 25px;
    border-radius: 0;
    background-color: #efefef;
    overflow: visible;
}

.progress-bar {
    background-color: #00ACED;
    position: relative;
}

.progress-bar span {
    font-size: 11px;
    font-weight: 100;
    font-family: "Open Sans", sans-serif;
    background-color: #212226;
    padding: 3px;
    position: absolute;
    left: 97%;
    top: -47px;
}

.progress-bar span:before {
    content: "";
    border-top: 8px solid #212226;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    position: absolute;
    bottom: -8px;
    left: 23%;
}

/*=================================================
||||||| Statement
=================================================*/

#statement {
    background: url("../img/tech/tech-quote.jpg") no-repeat fixed center;
    background-size: cover;
    /*background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    using simplification of all this code into */
}

#statement .content-box-lg {
    background: rgba(0, 0, 0, 0.3);
}

#mision-statement h3 {
    color: #fff;
    font-size: 28px;
    line-height: 45px;
}

#mision-statement p {
    font-size: 16px;
    color: #f4f4f4;
    font-family: "Raleway", sans-serif;
    margin-top: 8px;

}

/*=================================================
||||||| Services 01
=================================================*/

#services-01 img {
    margin: 60px 0;
}

.service {
    margin: 35px 0;
}

.service .icon {
    font-size: 42px;
    margin: 0;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

.service:hover .icon {
    color: #00ACED;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
}

.service h5 {
    font-size: 12px;
    text-transform: uppercase;
    color: #00ACED;
    margin: 0 0 6px 0;
}

.service:hover h5 {
    color: #212226;
}

.service h4 {
    font-size: 20px;
    margin: 0 0 8px 0;
}

.service p {
    line-height: normal;
    text-align: justify;
}

/*=================================================
||||||| Services 02 with responsive Tabs
=================================================*/

#services-02 {
    background: #f4f4f4;
}

#services-tabs ul {
    margin-bottom: 40px;
}

#services-tabs ul li {
    padding: 5px 30px;

}

#services-tabs ul li a {
    font-family: "Raleway", sans-serif;
    font-size: 13px;
    color: #212226;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0;
    text-decoration: none;
}

/* Tab active state */

#services-tabs ul li.r-tabs-state-active a {
    color: #00ACED;
    font-weight: 600;
    border-bottom: 3px solid #00ACED;
    padding-bottom: 5px;
    -webkit-transition: border-color 300ms linear;
    transition: border-color 300ms linear;

}

.service-tab .col-md-6 {
    padding: 0;
}

.service-tab img {
    width: 100%;
    margin: 0 auto;
}

.service-tab .tab-bg {
    background: #fff;
    padding: 59px 30px;
    min-height: 481px;
}

.service-tab h2 {
    font-size: 48px;
    color: #bbb;
    margin-bottom: 20px;
    font-weight: 100;
}

.service-tab h3 {
    font-size: 32px;
    margin-bottom: 30px;

}

.service-tab p {
    line-height: 28px;
    margin-bottom: 30px;
    text-align: justify;

}
.content-box-lg-services {
    padding-top: 90px;
    padding-bottom: 0;
    margin-bottom: 0
}

.content-box-md-services {
    padding-top:25px;
}

/*=================================================
||||||| Portfolio
=================================================*/

#portfolio {
    padding-bottom: 160px;
}

#portfolio .vertical-heading {
    margin-bottom: 70px;
}

#portfolio .vertical-heading h2 {
    margin-top: 25px;
    margin-left: 40px
}

.row.no-gutters [class*=col-] {
    padding: 0;

}

.portfolio-item {
    overflow: hidden;
    position: relative;
}

/* Filters CSS */

#isotope-filters {
    margin-bottom: 10px;
    padding: 20px 25px 20px 0;
}

#isotope-filters button {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-right: 30px;
    outline: 0;
}

#isotope-filters button span {
    display: block;
    padding-bottom: 5px;
    -webkit-transition: border-color .4s ease-in-out;
    transition: border-color .4s ease-in-out;
}

#isotope-filters button.active span {
    color: #00ACED;
    border-bottom: 3px solid #00ACED;
}


/* zoom effect */

.portfolio-item img {
    width: 100%;
    height: auto;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
}

.portfolio-item:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.portfolio-item {
    position: relative;
  }


/* Overlay effec */

.portfolio-item-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    cursor: zoom-in;
    opacity: 0;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

/* Portfolio Item Detailes effect */


.portfolio-item-detais .brand-copy h3 {
    color: #fff;
    font-size: 24px;
    margin: 0;
    padding: 0;
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.portfolio-item-detais span {
    display: inline-block;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    height: 6px;
    width: 30px;
    margin: 11px auto 5px auto;
}

.portfolio-item-detais h5 {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    color: #fff;
    margin: 10px 0;
    padding: 0;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.portfolio-item-detais p {
    font-size: 17px;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

/* Show Overlay on Mouse Over*/

.portfolio-item:hover .portfolio-item-detais h3 {
    -webkit-transform: translateY(0);
    transform: translateY(0%);
}

.portfolio-item:hover .portfolio-item-detais p {
    -webkit-transform: translateY(0);
    transform: translateY(0%);
}

.portfolio-item:hover .portfolio-item-overlay {
    opacity: 1;
}

/*=================================================
||||||| Testimonial
=================================================*/

#testimonials {
    background: #f4f4f4;
    padding-bottom: 45px;
    overflow: visible;
}

#testimonials .vertical-heading {
    padding: 80px 0;
    margin-bottom: 50px;
}

#testimonial-slider {
    background: #00ACED;
    top: -120px;
}

.testimonial {
    padding: 20px 45px 0 45px;
    color: #fff;
}

.testimonial .row {
    margin-bottom: 25px;
}

.testimonial h3 {
    font-size: 28px;
    line-height: 35px;
}

.testimonial .stars {
    font-size: 20px;
    margin: 20px 0 10px 0;
}

.testimonial p {
    line-height: normal;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 20px;
}

.author .author-name-des {
    width: 200px;
    margin-top: 25px;
    text-align: left;
}

.author .author-name-des p {
    margin: 0;
}

/*=================================================
||||||| Stats
=================================================*/

#stats {
    background: url("../img/stats/stats-bg.jpg") no-repeat fixed center;
    background-size: cover;
}

#stats .content-box-md {
    background: rgba(0, 0, 0, 0.8);
}

.vertical-heading h2 {
    /*added by me specially for this page */
    padding-left: 25px;
    padding-top: 20px;
}

#stats .vertical-heading {
    margin-bottom: 50px;
}

#stats .vertical-heading h2 {
    color: #fff;
}

.stats-item {
    background: rgba(61, 68, 68, 0.6);
    border-radius: 4px;
    min-height: 170px;
    padding: 25px 50px;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

.stats-item i {
    font-size: 42px;
    color: #00ACED;
    margin: 0 0 6px 0;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

.stats-item h3 {
    font-size: 32px;
    color: #fff;
    margin: 5px 0 5px 0;
}

.stats-item p {
    color: #fff;
    margin: 0;
}

.stats-item:hover {
    background: #00ACED;
}

.stats-item:hover i {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    color: #212226;

}

/*=================================================
||||||| Clientes
=================================================*/

#clients .horizontal-heading {
    margin-bottom: 30px;
}

#clients p {
    margin-bottom: 25px;
    text-align: justify;
    
}

#client-list {
    padding: 45px 0 0 0;
}

.cliente {
    padding: 0 10px;
    max-width: 250px;
    margin: 0 auto;
    line-height: 100px;
    /*used jontly to align the logos vertically */
}

.cliente img {
    display: inline-block !important;
    /*used jontly to align the logos vertically. Note the Important! definition as it overrides the 'inline' normal definition with inilie-block */
}

/*=================================================
||||||| contact 
=================================================*/

.invalid-feedback {
    position: absolute;
    font-size: 10px;
    font-weight: 300;
    color: red;
}

#contact {
    background-color: #f4f4f4;
}

/* contact Lefft Side */

#contact-left p {
    margin: 35px 0 20px 0;
}

#office {
    padding-top: 16px;
    padding-left: 65px;
}

ul.office-details {
    list-style: none;
    padding: 0;
}

ul.office-details li {
    font-family: "Open Sans", sans-serif;
    margin-top: 12px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: flex;
    /* display flex, properly align the icon (children) and looks great */
}

ul.office-details li i {
    font-size: 20px;
    width: 45px;

}

ul.office-details li span {
    display: inline-block;
}

#contact-left ul.social-list li a {
    color: #212226;
    border: 1px solid #212226;
    text-align: center;
}

#contact-left ul.social-list li a:hover {
    color: #fff;
    border-color: transparent;
}

/* contact right Side */

#contact-right {
    background: #f4f4f4;
    padding: 25px 30px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

#contact-right h4 {
    font-size: 24px;
}

#contact-right p {
    margin-bottom: 25px;
}

#contact-right form .form-control {
    font-family: "Open Sans", sans-serif;
    padding: 10px 15px;
    font-size: 12px;
    line-height: 24px;
}

#contact-right form textarea.form-control {
    min-height: 164px;
}

#check-form {
    font-family: "Open Sans", sans-serif;
    padding: 10px 5px 10px 5px;
    font-size: 12px;
    line-height: 24px;
    display: inline-block;
}

#submit-btn {
    padding: 0;
    margin-top: 18px;
    margin-right: 18px;
    text-align: right;
}

/* event-popup for dynamic responses to user */


#white-popup {
    font-family: "Raleway", sans-serif;
    position: relative;
    background: #f4f4f4;
    padding: 20px;
    width: auto;
    max-width: 500px;
    max-height: 400px;
    margin: 20px auto;;
    border-radius: 4px;
}

#white-popup h3 {
    color: #212226;
    font-size: 22px;
    font-weight: 200;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 30px;
    display: inline-block;
}

#white-popup p {
    color: #212226;
    font-size: 15px;
    font-weight: 50;
    margin-top: 5px;
    margin-bottom: 20px;

}

/*Form errors*/

.form-error {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 300;
    color: #dd4b39;
}

/*=================================================
||||||| Google Map
=================================================*/

#map {
    height: 600px;

}

/*=================================================
||||||| footer area
=================================================*/

footer {
    background: #212226;
    padding: 30px
}

footer p {
    color: #fff;
    line-height: 20px;
    margin: 0;
}

footer p span {
    color: #00ACED;
}

/*=================================================
||||||| Navigation bar
=================================================*/

.navbar {
    padding: 35px 0 20px 0;
    transition: all 300ms linear;
    /*It slows down the navbar apeareance after jQuery changes status and classes */
}

.site-nav-wrapper {
    padding: 0 85px;
}

.navbar-brand {
    padding: 0px 15px;
    /*Changed to 0 top so AO logo could fit ar 50px height */
}

ul.navbar-nav > li > a {
    font-family: "Raleway", sans-serif;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
}

ul.navbar-nav > li > a:hover,
ul.navbar-nav > li > a:focus {
    background: none;
    color: #00ACED;
}

/* White Navigation */

.white-nav-top {
    background: #fff;
    padding: 10px 0;
    -webkit-box-shadow: 0 8px 6px -9PX #999;
    box-shadow: 0 8px 6px -9PX #999;
    z-index: 2;
}

.white-nav-top ul.navbar-nav > li > a {
    color: #212226;
}

.white-nav-top ul.navbar-nav > li > a:hover,
.white-nav-top ul.navbar-nav > li > a:focus {
    color: #00ACED;
}

/*Scroll Spy active state */

.white-nav-top ul.navbar-nav > li.active > a {
    color: #00ACED;
    font-weight: 500;

}

/*=================================================
||||||| Animation - adjustments to animation done by animate .css
=================================================*/

#home-heading-1,
home-heading-2,
home-text,
home-btn,
arrow-down i {
    animation-duration: 0.5s;
}

#home-heading-1 {
    animation-delay: 0.5s;
}

#home-heading-2 {
    animation-delay: 0.8s;
}

#home-text {
    animation-delay: 1.0s;
}

#home-btn {
    animation-delay: 1.2s;
}

#arrow-down i {
    animation-delay: 1.5s;
}