/*=================================================
||||||| Mobile Menu
=================================================*/

.navbar-header {
    position: relative;
}

#mobile-nav-open-btn {
    font-size: 30px;
    color: #00ACED;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    right: 0;
    top: 6px;
    /*Hide mobile nagigation in general terms. Later we will show it only for small and xtra small screens*/
    display: none;
}

#mobile-nav {
    /* by default, mobile nav will be hidden with height 0% */
    height: 100%;
    width: 100%;
    position: fixed;
    ;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: rgba(255, 255, 255, .9);
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    overflow: hidden;
}

#mobile-nav-close-btn {
    font-family: "Raleway", sans-serif;
    font-size: 70px;
    color: #212262;
    font-weight: 400;
    cursor: pointer;
    position: absolute;
    top: -9px;
    right: 13px;
    -webkit-transition: .3s;
    transition: .3s;
}

#mobile-nav-content {
    text-align: center;
    width: 100%;
    margin-top: 30px;
    position: relative;
    top: 10%;
}

#mobile-nav-content ul li {
    margin-bottom: 20px;
}

#mobile-nav a {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    color: #212226;
    font-weight: 400;
    text-transform: uppercase;
    display: inline;
    -webkit-transition: .3s;
    transition: .3s;
}

#mobile-nav a:hover,
#mobile-nav a:focus,
#mobile-nav-close-btn:hover,
#mobile-nav-close-btn:focus {
    color: #00ACED;
    background: none;
}

/*Mobile Nav Scroll Spy active state */

.white-nav-top #mobile-nav ul.nav > li.active > a {
    color: #00ACED;
    font-weight: 500;

}



/*=================================================
||||||| Bootstrap 3 Media Queries
=================================================*/

/* Large Devices (Desktops & Laptops) */

@media (min-width: 1200px) and (max-width: 1299px) {

    /* Navigation bar adjustment */
    .site-nav-wrapper {
        padding: 0 20px;

    }

}

/* Medium Devices (Landscape tablets & Medium Desktops) */

@media (min-width: 992px) and (max-width: 1199px) {

    /* Navigation bar adjustment */
    .site-nav-wrapper {
        padding: 0;
    }

    /* Adjusting font size for all vertical headings in this screen size */
    .vertical-heading h2 {
        font-size: 29px;

    }

    /* Including Adjustment team section */
    #team-left h2 {
        padding: 0 30px;
    }

    /* Adjusting size of services tabs 02 */
    #services-tabs .tab-bg {
        padding: 16px 30px;
        min-height: 300px;
    }
    .service-tab p {
        line-height: 25px;
    }

}

/* Small Devices (Portrait Tablets & Small Desktops) */

@media (min-width: 768px) and (max-width: 992px) {

    /* Navigation bar adjustment */
    .site-nav-wrapper {
        padding: 0;
    }

    /* Home adjustment */
    #home-heading h1 {
        font-size: 41px;
    }

    #home-text p {
        font-size: 13px;
        ;
    }

    /*Reduce size for button elements in small screens */
    .btn-general {
        padding: 8px 35px 8px 35px;
        font-size: 10px;
    }

    /* Adjusting font size for all vertical headings in this screen size */
    .vertical-heading h2 {
        font-size: 29px;

    }

    /*Text statement */
    #mision-statement h3 {
        font-size: 20px;
        line-height: 30px;
        ;
    }

    /*services 02 */
    .r-tabs-accordion-title {
        text-align: center;
        margin-bottom: 10px;
    }
    .r-tabs-accordion-title a {
        font-family: "Raleway", sans-serif;
        font-size: 13px;
        color: #212226;
        text-transform: uppercase;
        font-weight: 500;
        padding: 0;
        text-decoration: none;
    }
    .r-tabs-accordion-title.r-tabs-state-active a {
        color: #00ACED;
        font-weight: 600;
        border-bottom: 3px solid #00ACED;
        padding-bottom: 5px;
        -webkit-transition: border-color 300ms linear;
        transition: border-color 300ms linear;

    }

    /* Menu text for portfolio - Small adjustment to show menu in one line*/
    #isotope-filters button {
        font-size: 11px;
    }
    .stats-item h3.m2 {
        /* Adjusting specifically the margin for the m2 of construcion for small screens */
        margin-left: -30px;

    }
    /*skills progress bars */
    .skill h4 {
        line-height: 20px;
        font-size: 16px;
        padding-bottom: 6px;
    }

    /*stats */
    .stats-item p {
        line-height: 18px;
    }


    /* Contact */
    .content-box-md {
        /*reducing from 100 to 60 in the small screens  for better viewing */
        padding-top: 60px;
    }

    ul.office-details li {
        margin-right: 15px;
    }

    ul.office-details li i {
        width: 25px;
    }
}

/* Extra Small Devices (Landscape Phones & Portrait tablets) */

@media (max-width: 767px) {


    /* Navigation */
    .navbar {
        padding: 20px 0;
    }

    .site-nav-wrapper,
    .white-nav-top {
        padding: 0;
    }

    /* Showing the mobile menue open button on extra small devices*/
    #mobile-nav-open-btn {
        display: block;
    }

    #home-bg-video {
        display: none; /* Hide the video on small screens */
      }
    
    #home-bg-image {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -1;
        background: url("../img/tech/tech-quote.jpg") no-repeat;
        background-size: cover;
    }

    /* Home adjustment */
    #home-heading h1 {
        font-size: 22px;
    }

    #home-text p {
        font-size: 13px;
        line-height: 18px;
        margin: 8px 2px 30px 2px;
    }

    /*Reduce size for button elements in small screens */
    .btn-general {
        padding: 8px 25px 6px 25px;
        font-size: 10px;
    }

    /*about part 01 */
    /*large content box */
    .content-box-lg {
        padding: 90px 0;
    }

    /* Vertical heading */
    .vertical-heading {
        text-align: center;
    }

    .vertical-heading h5 {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        display: block;
        position: static;
        margin-bottom: 15px;
    }
    .vertical-heading h2 {
        font-size: 29px;
        line-height: normal;
        margin: 0;
        padding: 0;
    }

    #about-left {
        margin-bottom: 50px;
    }

    #about-right {
        text-align: center;
    }

    /* About 02 */
    /* Medium content box */
    .content-box-md {
        padding: 80px 0;
        ;
    }

    .about-item {
        padding: 55px 30px;
        margin-bottom: 35px;
    }
    .about-item i {
        font-size: 30px;

    }
    .about-item h3 {
        font-size: 18px;
    }

    /* Team Section */
    #team-left .vertical-heading {
        min-height: 130px;
    }

    /* font size */
    .skill h4 {
        font-size: 14px;
        line-height: 25px;
    }

    /* tech statement */
    #mision-statement h3 {
        font-size: 20px;
        line-height: 30px;
        ;
    }

    /*Services 01 */
    /* Horizontal heading */
    .horizontal-heading {
        margin-bottom: 40px;
    }

    .horizontal-heading h2 {
        font-size: 30px;
    }

    #services-01 img {
        margin: 35px 0;
    }

    .service,
    .service .icon {
        text-align: center;
    }

    .service h4 {
        font-size: 18px;
        ;
    }

    /*services 02 */
    .r-tabs-accordion-title {
        text-align: center;
        margin-bottom: 10px;
    }
    .r-tabs-accordion-title a {
        font-family: "Raleway", sans-serif;
        font-size: 13px;
        color: #212226;
        text-transform: uppercase;
        font-weight: 500;
        padding: 0;
        text-decoration: none;
    }
    .r-tabs-accordion-title.r-tabs-state-active a {
        color: #00ACED;
        font-weight: 600;
        border-bottom: 3px solid #00ACED;
        padding-bottom: 5px;
        -webkit-transition: border-color 300ms linear;
        transition: border-color 300ms linear;

    }
    #services-tabs .tab-bg {
        text-align: center;
        padding: 40px 30px;
    }
    #services-tabs h2 {
        font-size: 40px;
    }

    #services-tabs h3 {
        font-size: 28px;
    }

    /* portfolio section */
    #portfolio {
        padding-bottom: 0;
    }

    #isotope-filters button {
        padding-right: 15px;
    }

    /* Testimonial */
    #testimonials {
        padding-top: 45px;
    }

    #testimonial-slider {
        top: 0;
    }

    #testimonials .vertical-heading {
        padding: 0;
    }
    .testimonial .row {
        margin: 0;
    }

    .testimonial h3 {
        font-size: 20px;
    }

    .testimonial .stars {
        font-size: 16px;
    }

    .author img {
        width: 80px !important;
    }

    /*stats */
    .stats-item {
        margin-bottom: 20px;
        padding: 25px 5px;
    }
    .stats-item i {
        font-size: 30px;
    }
    .stats-item h3 {
        font-size: 24px;
    }

    /*Contact */
    #office {
        padding-left: 0px!important;
    }

    #contact-left {
        text-align: center;
    }

    .office {
        margin-bottom: 20px;
    }

    ul.office-details li {
        display: block;
        margin-top: 25px;
    }

    ul.office-details li i {
        width: auto;
        display: block;
        margin-bottom: 3px;
    }

    ul.social-list {
        margin-bottom: 40px;
    }
}

/* Extra Small devices below 660px - portraits phones and smaller devices*/

@media (max-width: 660px) {
    /* portfolio section */
    div #isotope-filters {
        padding-right: 0;
        padding-left: 0;
    }

    #isotope-filters {
        text-align: center;
    }

    #isotope-filters button {
        font-size: 13px;
        font-weight: 600;
        padding: 0 12px;
    }

    /*Testimonials */
    .testimonial h3 {
        font-size: 14px;
        line-height: 18px;
    }

    .testimonial .stars {
        font-size: 13px;

    }

}

/* Extra Small devices below 480px - portraits phones and smaller devices*/

@media (max-width: 480px) {
    /* Home adjustment */
    #home-heading h1 {
        font-size: 28px;
    }

    #home-text p {
        font-size: 16px;
        line-height: 16px;
        margin: 8px 10px 25px 10px;
    }

    /* Vertical heading */
    .vertical-heading h2 {
        font-size: 22px;
        line-height: normal;
        margin: 0;
        padding: 0;
    }

    /* portfolio section */
    div #isotope-filters {
        padding-right: 0;
        padding-left: 0;
    }

    #isotope-filters {
        text-align: center;
    }

    #isotope-filters button {
        font-size: 10px;
        font-weight: 600;
        padding: 0 3px;
    }

    /*Testimonials */
    .testimonial h3 {
        font-size: 14px;
        line-height: 18px;
    }

    .testimonial .stars {
        font-size: 13px;

    }
}

/* Extra Small devices below 320px - portraits phones and smaller devices*/

@media (max-width: 320px) {

    /*Testimonials */
    .testimonial h3 {
        font-size: 12px;
        line-height: 16px;
    }

    .testimonial .stars {
        font-size: 10px;

    }

}